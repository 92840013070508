@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #ebe6e5
}

.custom-gradient {
    background-image: linear-gradient(135deg, #800080, #008080);
}

.container {
    height: 100vh;
}

.gradient-custom {
    /* fallback for old browsers */
    background: #008080;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to bottom right, #800080, #008080);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to bottom right, #800080, #008080);
}